<div class="card-container">
  <div *ngIf="isFirst" class="group-heading">{{firstLetter.toUpperCase()}}</div>
  <mat-card class="file-card">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between">
      <div class="card-file-name" fxFlex="30">{{file.name}}</div>
      <div fxFlex="8">{{file.version}}</div>
      <div fxFlex="11">{{file.releaseDate | date:'dd.MM.yy'}}</div>
      <div fxFlex="45">{{file.notes || '&nbsp;'}}</div>
      <div class="release-notes-button" fxFlex="9">
        <mat-icon *ngIf="file.projectId" matTooltip="Release Notes" (click)="showReleaseNotesModal(file)">assignment</mat-icon>
      </div>
      <div class="card-download-link" fxFlex fxLayoutAlign=end>
        <a [href]="file.url"><mat-icon>get_app</mat-icon></a>
      </div>
    </mat-card-content>
  </mat-card>
</div>
