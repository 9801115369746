import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChannelModel } from '../models/channel-model';

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {
  private _baseUrl = 'api/channels';

  constructor(private _http: HttpClient) { }

  getAll() {
    return this._http.get<ChannelModel[]>(this._baseUrl);
  }

  getSingleChannel(channelName: string) {
    return this._http.get<ChannelModel>(`${this._baseUrl}/${channelName}`);
  }

}
