import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FileUploadService } from '../../services';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

  @ViewChild('fileUpload') fileUpload;
  @ViewChild('form') form;
  @Input() channel = 'Release';

  constructor(private readonly _fileUploadService: FileUploadService) { }

  uploadFile() {
    const file = this.fileUpload.nativeElement.files[0];

    if (!file) { return; }

    this._fileUploadService.uploadFile(file, this.channel).subscribe(x => {
      this.fileUpload.nativeElement.value = null;
      console.log(x);
    });
  }

  ngOnInit(): void {
  }

  selectFile() {
    this.fileUpload.nativeElement.click();
  }

}
