<div class="release-notes">
  <div class="outer-container">
    <h2>Release Notes for {{file.name}}</h2>
    <div class="inner-container">
      <div *ngIf="releaseNotesData && releaseNotesData.versions && releaseNotesData.versions.length; else noData">
        <div *ngFor="let versionLevel of releaseNotesData.versions">
          <h3>Version {{versionLevel.version}}</h3>
          <div *ngFor="let issueTypeLevel of versionLevel.issueTypes">
            <h4>{{issueTypeLevel.issueType}}s</h4>
            <ul>
              <li *ngFor="let item of issueTypeLevel.releaseNotes">#{{item.id}} : {{item.narrative}}</li>
            </ul>
          </div>
        </div>
      </div>
      <ng-template #noData>There are no release notes available for this component</ng-template>
    </div>
  </div>
</div>

