import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileModel } from '../../models';
import { ReleaseNotesComponent } from '../release-notes/release-notes.component';

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileCardComponent implements OnInit {
  @Input() file: FileModel;
  @Input() isFirst = false;
  @Input() firstLetter: string;

  constructor(private readonly _matDialog: MatDialog) { }

  ngOnInit() {
  }

  showReleaseNotesModal(file: FileModel) {
    this._matDialog.open(ReleaseNotesComponent, {
      data: file
    });
  }
}
