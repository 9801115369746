<footer id="impressum">
  <p>&nbsp;</p>
  <p>SIUS AG</p>
  <p>Im Langhag 1</p>
  <p>8307 Effretikon</p>
  <p>SWITZERLAND</p>
  <p>&nbsp;</p>
  <p>Mail: <a href="mailto://info@sius.com">info@sius.com</a></p>
  <p>&nbsp;</p>
  <p>Tel: +41 (0)52 354 60 60</p>
  <p>&nbsp;</p>
  <p><a href="#" (click)="privacy($event)">Privacy Policy</a></p>
</footer>
