<div id="file-upload-container">
  <form><input #fileUpload id="file-upload" type="file"
               accept=".json"
               (change)="uploadFile()" /></form>
  <div id="file-upload-outer" class="app-rounded-button-container">
    <button (click)="selectFile()">
      <mat-icon>cloud_upload</mat-icon>
      <div>Upload inventory.json file</div>
    </button>
  </div>
</div>
