<div id="page-container">
  <mat-toolbar class="main-toolbar mat-toolbar" role="toolbar">
    <mat-toolbar-row class="mat-toolbar-row">
      <div id="sius-logo-container">
        <a href="/"><img src="../../assets/icons/512x512.png" width="60" alt="SIUS" /></a>
      </div>
      <h2>Offline Update</h2>
    </mat-toolbar-row>
  </mat-toolbar>
  <app-file-upload *ngIf="!files || noFiles" [channel]="channel"></app-file-upload>
  <h4 *ngIf="downloading" class="message">
    ZIP file downloading. You will be returned to the main page in a few seconds</h4>
  <h4 *ngIf="noFiles" class="message">
    No corresponding files found
  </h4>
  <div *ngIf="isFirefox() && files && files.length">
    <app-file-card *ngFor="let file of files"
                   [file]="file">
    </app-file-card>
  </div>
</div>
