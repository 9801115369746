<div id="page-container">
  <mat-toolbar class="main-toolbar mat-toolbar" role="toolbar">
    <mat-toolbar-row class="mat-toolbar-row">
      <div id="sius-logo-container">
        <img src="../../assets/icons/512x512.png" width="60" alt="SIUS" />
      </div>
      <div id="range-download-container">
        <a id="range-download-link" [href]="getOfflineUpdateUrl()">Offline Update</a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <app-search-box (searchChanged)="search($event)"></app-search-box>
  <div id="breaker"></div>
  <div id="results-container">
    <div id="files" *ngIf="filteredFiles">
      <div class="file-group" *ngFor="let grouping of groupedFilteredFiles">
        <app-file-card *ngFor="let file of grouping.files; index as i"
                       [file]="file"
                       [isFirst]="i === 0"
                       [firstLetter]="grouping.firstLetter">
        </app-file-card>
      </div>
    </div>
    <app-impressum></app-impressum>
  </div>
</div>
