import { Injectable } from '@angular/core';

import { FileModel } from '../models';

declare const streamSaver: any;
declare const ZIP: any;
const window_ = window as any;

@Injectable({
  providedIn: 'root'
})
export class ZipStreamService {

  constructor() { }

  createZip(files: FileModel[]) {
    const fileStream = streamSaver.createWriteStream('sius-components-archive.zip');

    const readableZipStream = new ZIP({
      start(_) { },
      async pull(ctrl) {
        for (const file of files) {
          const res = await fetch(file.url, {
            headers: { 'keep-alive': 'timeout=3600'}
          });

          const stream = () => res.body;
          ctrl.enqueue({ name: file.downloadFileName, stream: stream }); 
        }

        ctrl.close();
      }
    });

    // more optimized
    if (window_.WritableStream && readableZipStream.pipeTo) {
      return readableZipStream.pipeTo(fileStream).then(() => console.log('done writing'));
    }

    // less optimized
    const writer = fileStream.getWriter();
    const reader = readableZipStream.getReader();
    const pump = () => reader.read()
      .then(res => res.done ? writer.close() : writer.write(res.value).then(pump));

    pump();
  }
}
