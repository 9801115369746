import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyComponent } from '../privacy/privacy.component';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  constructor(private readonly _matDialog: MatDialog) { }

  ngOnInit(): void {
  }

  privacy(evt) {
    this._matDialog.open(PrivacyComponent);
    evt.preventDefault();
  }

}
