import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileModel, ReleaseNotesHierarchyRoot } from '../../models';
import { ReleaseNotesService } from '../../services';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {

  releaseNotesData: ReleaseNotesHierarchyRoot;

  constructor(
    @Inject(MAT_DIALOG_DATA) public file: FileModel,
    dialogRef: MatDialogRef<ReleaseNotesComponent>,
    private readonly _releaseNotesService: ReleaseNotesService) { }

  ngOnInit(): void {
    this._releaseNotesService
      .getReleaseNotes(this.file.projectId, this.file.version)
      .subscribe(data => this.releaseNotesData = data);
  }

}
