import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {
  FileDisplayComponent,
  OfflineUpdateComponent
} from './containers';

const routes = [
  { path: 'file-display/:channel', component: FileDisplayComponent },
  { path: 'file-display', redirectTo: '/file-display/release', pathMatch: 'full' },
  { path: 'offline-update/:channel', component: OfflineUpdateComponent },
  { path: 'offline-update', redirectTo: '/range-download/release', pathMatch: 'full' },
  { path: '', redirectTo: '/file-display/release', pathMatch: 'full' }

];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
