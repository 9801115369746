import {
  Component, OnInit,
  EventEmitter, Output
} from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {

  @Output() searchChanged = new EventEmitter<string>();
  searchTerm = '';

  constructor() { }

  ngOnInit() {
  }

  search() {
    this.searchChanged.emit(this.searchTerm);
  }

}
