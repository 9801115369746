import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FileUploadService, ZipStreamService } from '../../services';
import { FileModel } from '../../models';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-offline-update',
  templateUrl: './offline-update.component.html',
  styleUrls: ['./offline-update.component.scss']
})
export class OfflineUpdateComponent implements OnInit {

  constructor(
    private readonly _fileUploadService: FileUploadService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _zipStreamService: ZipStreamService) { }

  channel = 'Release';
  files: FileModel[] | null;
  noFiles = false;
  downloading = false;

  ngOnInit(): void {
    if (this._route.params) {
      this._route.params.subscribe(
        qParams => {
          const channel = qParams['channel'];
          if (channel) {
            this.channel = channel;
          }
        });
    }

    this._fileUploadService.$newFiles.subscribe(fs => {
      this.files = fs;
      if (this.files && !this.files.length) {
        this.noFiles = true;
      } else {
        this.noFiles = false;
        if (!this.isFirefox()) {
          this.downloading = true;
          this._zipStreamService.createZip(this.files);
          setTimeout(() => this._router.navigateByUrl('/'), 10000);
        }
      }
    });
  }

  downloadZip() {
    this._zipStreamService.createZip(this.files);
  }

  // firefox doesn't have very good support for the stream api, which
  // the zip download relies on
  isFirefox() {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > 0;
  }
}
