import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { FileModel } from '../models/file-model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _snackBar: MatSnackBar) { }

  $newFiles = new Subject<FileModel[]>();

  uploadFile(file, channel = 'Release') {
    this.$newFiles.next(null);

    const formData: FormData = new FormData();
    formData.append('inventory.json', file, file.name);

    return this._httpClient
      .post<FileModel[]>(`api/offline-update/inventory-file-post/${channel}`, formData)
      .pipe(
        tap(res => this.$newFiles.next(res)),
        catchError(err => {
          let msg = 'There was an error attempting to read the uploaded file';
          if (err.status === 400) {
            msg = err.error;
          }
          this._snackBar.open(msg, 'Error', { duration: 5000 });
          return err;
        }));
  }
}
