import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChannelModel } from '../models/channel-model';
import { ReleaseNotesHierarchyRoot } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {
  private _baseUrl = 'api/release-notes';

  constructor(private _http: HttpClient) { }

  getReleaseNotes(projectId: string, version: string) {
    return this._http.get<ReleaseNotesHierarchyRoot>(`${this._baseUrl}/${projectId}/${version}`);
  }
}
