import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {
  FileGroup,
  FileModel
} from '../../models';
import { ChannelsService } from '../../services';

@Component({
  selector: 'app-file-display',
  templateUrl: './file-display.component.html',
  styleUrls: ['./file-display.component.scss']
})
export class FileDisplayComponent implements OnInit {
  files: FileModel[];
  filteredFiles: FileModel[];
  channel = 'Release';
  searchTerm = '';
  groupedFilteredFiles: FileGroup[] = [];

  constructor(
    private _channelsService: ChannelsService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    if (this._route.params) {
      this._route.params.subscribe(
        qParams => {
          const channel = qParams['channel'];
          if (channel) {
            this.channel = channel;
          }

          this._channelsService
            .getSingleChannel(this.channel)
            .subscribe(result => {
              this.files = result.files;
              this.filteredFiles = [...this.files];
              this.arrangeIntoGroups();
            });
        });
    }
  }

  getOfflineUpdateUrl() {
    return `offline-update/${this.channel}`;
  }

  search(searchTerm: string) {
    this.searchTerm = searchTerm;

    if (!this.files) {
      return;
    }

    if (!this.files.length) {
      this.filteredFiles = [...this.files];
      this.arrangeIntoGroups();
      return;
    }

    if (!this.searchTerm.length) {
      this.filteredFiles = [...this.files];
      this.arrangeIntoGroups();
      return;
    }

    const searchTermLower = this.searchTerm.toLowerCase();

    this.filteredFiles = [];
    for (const file of this.files) {
      if (file.name.toLowerCase().includes(searchTermLower)) {
        this.filteredFiles.push(file);
      } else if (file.notes && file.notes.length && file.notes.toLowerCase().includes(searchTermLower)) {
        this.filteredFiles.push(file);
      } else if (file.tags && file.tags.length) {
        for (const tag of file.tags) {
          if (tag && tag.length && tag.toLowerCase().includes(searchTermLower)) {
            this.filteredFiles.push(file);
          }
        }
      }
    }

    this.arrangeIntoGroups();
  }

  arrangeIntoGroups() {
    this.groupedFilteredFiles = [];

    this.filteredFiles.sort((a, b) => {
      const aName = a.name.toLowerCase(),
        bName = b.name.toLowerCase();

      if (aName === bName) { return 0; } else if (aName < bName) { return -1; } else { return 1; }
    });

    for (const file of this.filteredFiles) {
      const firstLetter = file.name.toLowerCase()[0];

      let thisGroup = this.groupedFilteredFiles.find(x => x.firstLetter === firstLetter);

      if (!thisGroup) {
        thisGroup = { firstLetter: firstLetter, files: [] };
        this.groupedFilteredFiles.push(thisGroup);
      }

      thisGroup.files.push(file);
    }
  }
}
