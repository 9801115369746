import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'software.sius.com';

  public constructor(private titleService: Title, private router: Router) {
    this.titleService.setTitle(this.title);

    const navEndEvent$ = router.events.pipe(filter(e => e instanceof NavigationEnd));
    navEndEvent$.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-5340915-3', { 'page_path': event.urlAfterRedirects });
    });
  }
}
